import { useGoogleLogin } from '@react-oauth/google'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

const GoogleLogin = () => {
    const [googleAuth, setGoogleAuth] = useState({})
    const [userInfo, setUserInfo] = useState({})

    const handleLogin = useGoogleLogin({
        onSuccess: (response) => {
            setGoogleAuth(response)
            localStorage.setItem("googleAuth", JSON.stringify(response))
        },
        onError: (error) => console.log(error),
        scope: 'profile email',
        access_type: 'offline', // Request offline access for refresh token
        prompt: 'consent',
    })

    const getUserDetails = async () => {
        try {
            const { data } = await axios.get(
                `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleAuth.access_token}`,
                {
                    headers: {
                        Authorization: `Bearer ${googleAuth.access_token}`,
                        Accept: "application/json",
                    },
                }
            );
            setUserInfo(data)
        } catch (error) {
            console.log(error);
            localStorage.removeItem("googleAuth")
        }
    }

    useEffect(() => {
        const authGoogle = JSON.parse(localStorage.getItem("googleAuth"));
        if (authGoogle && Object.keys(authGoogle).length > 0) {
            setGoogleAuth(authGoogle)
        }
    }, [])

    useEffect(() => {
        if (googleAuth && Object.keys(googleAuth).length > 0) {
            getUserDetails()
        }
    }, [googleAuth])

    return (
        <div style={{ padding: "20px" }}>
            {Object.keys(userInfo).length === 0 && <button onClick={handleLogin}>Login with Google</button>}

            {userInfo && Object.keys(userInfo).length > 0 && (
                <div>
                    <h3>Google User Logged In</h3>
                    <img src={userInfo.picture} alt="user image" />
                    <p>Name: {userInfo.name}</p>
                    <p>Email Address: {userInfo.email}</p>
                </div>
            )}
        </div>
    )
}

export default GoogleLogin;