import os from 'os';
import { mediaUrl } from '../Network/path';


export function generateGuestUserId() {
  const timestamp = Date.now(); // Current timestamp
  const randomNumber = Math.floor(Math.random() * 1000000); // Random number between 0 and 999999

  // Get the hostname or other machine-specific details
  const hostname = os.hostname(); // Get the local machine's hostname

  // Optionally, you can hash the hostname or use a part of it to keep the ID shorter
  const guestUserId = `${hostname}${timestamp}${randomNumber}`; // Combine to create a unique ID

  return guestUserId;
}

export async function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
}

export function countMinusWithInputValue(totalValue, count) {
  return totalValue - count < 0
    ? totalValue - count
    : count === 0
    ? totalValue
    : totalValue - count === 0 || totalValue - count < 1
    ? 0
    : totalValue - count;
}


export function kFormatter(n) {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
}

export const downloadMedia = (data) => {
  const imgName = data.split(`${mediaUrl}`)[1];
  fetch(data, {
    method: "GET",
    headers: {},
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", imgName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
