import React, { useState } from "react";
import LeftSide from "./leftSide";
import RegisterForm from "./registerForm";

function Register() {
  return (
    <section class="login-ui">
      <div class="container py-100">
        <div class="row">
          <LeftSide />
          <RegisterForm />
        </div>
      </div>
    </section>
  );
}

export default Register;
