import * as yup from "yup"
export const emailregExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


export const registerFormSchema = yup.object({
    name: yup.string().required("name is required"),
    email: yup.string().required("email is required").matches(emailregExp, "Enter a valid email"),
    otp: yup
        .number()
        .typeError("OTP must be a number")
        .required("OTP is required")
        .min(1000, "OTP must be exactly 4 digits")
        .max(9999, "OTP must be exactly 4 digits")
});

export const loginSchema = yup.object({
    email: yup.string().required("Email is required").matches(emailregExp, "Enter a valid email"),
    otp: yup
        .number()
        .typeError("OTP must be a number")
        .required("OTP is required")
        .min(1000, "OTP must be exactly 4 digits")
        .max(9999, "OTP must be exactly 4 digits")
});