import React from "react";

const LeftSide = () => {
  return (
    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mobile-none">
      <div class="text-center">
        <h1>"Log in to continue chatting with your friends." </h1>
      </div>
    </div>
  );
};

export default LeftSide;
