import React from "react";

const Button = ({ title, type, className, children, onClick }) => {
  return (
    <button title={title} type={type} className={className} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
