import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import ChatWidget from "./ChatWidget";
import { ContextProvider } from "./Context";
import {
  setChats,
  setToken,
  setUser,
  setUserDetails,
} from "./Reducer/authSlice";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import * as process from "process";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Toaster } from "react-hot-toast";
window.global = window;
window.process = process;
window.Buffer = [];

const user =
  localStorage.getItem("userk") !== "undefined"
    ? JSON.parse(localStorage.getItem("userk"))
    : "";
const token = localStorage.getItem("tokenk")
  ? localStorage.getItem("tokenk")
  : "";
const userDetails =
  localStorage.getItem("userDetailsk") !== "undefined"
    ? JSON.parse(localStorage.getItem("userDetailsk"))
    : "";

const chats = localStorage.getItem("chatsk")
  ? JSON.parse(localStorage.getItem("chatsk"))
  : [];

if (user && token) {
  store.dispatch(setUser(user));
  store.dispatch(setToken(token));
}

if (userDetails) {
  store.dispatch(setUserDetails(userDetails));
}

if (chats) {
  store.dispatch(setChats(chats));
}
// localStorage.clear();

const APP = (
  <>
    <GoogleOAuthProvider clientId="58931365507-p2ovmq8rss04ukvik05q1gd0556tfe4l.apps.googleusercontent.com">
      <BrowserRouter>
        <Provider store={store}>
          <ContextProvider>
            <ToastContainer />
            <Toaster />
            <ChatWidget />
          </ContextProvider>
        </Provider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </>
);
const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(APP);
