const SimpleInput = ({
    inType,
    id,
    inlineStyle,
    style,
    name,
    event,
    placeholder,
    onclick,
    role,
    readOnly,
    errors,
    onChange,
    reff,
    value,
    disabled,
    multiple,
    accept,
    min,
    max,
    defaultValue,
    checked,
    focus,
    maxlength,
    arialabel
  }) => {
    // const v = { value, defaultValue };
    return (
      <>
        <input
        aria-label={arialabel}
          ref={reff}
          type={inType}
          id={id}
          className={style}
          style={inlineStyle}
          placeholder={placeholder}
          name={name}
          {...(event && event(name))}
          onClick={onclick}
          role={role}
          onChange={onChange}
          readOnly={readOnly}
          disabled={disabled}
          multiple={multiple}
          accept={accept}
          min={min}
          checked={checked}
          value={defaultValue && defaultValue !== "" ? defaultValue : value}
          autoFocus={focus}
          maxLength={maxlength}
          max={max}
        />
        {errors && errors[name] && (
          <p style={{ color: "red" }}>{errors[name].message}</p>
        )}
      </>
    );
  };
  export default SimpleInput;
  