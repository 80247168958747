import React, { useEffect, useState } from "react";
import Input from "../../Components/UI/input";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { secondUrl } from "../../Network/path";
import { useNavigate } from "react-router-dom";
import SocialRegister from "./socialRegister";
import { registerFormSchema } from "../../Helpers/formSchema";
import toast, { Toaster } from "react-hot-toast";
import { resendOtp } from "../../Services/api";

const RegisterForm = () => {
  const [terms, setTerms] = useState(false);
  const [otpStatus, setOtpStatus] = useState(false);
  const [remainingSeconds, setRemainingSeconds] = useState(120);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(registerFormSchema),
    mode: "onChange",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (otpStatus) {
      const intervalId = startTimer();
      // Cleanup function to clear the interval on unmount
      return () => clearInterval(intervalId);
    }
  }, [otpStatus, remainingSeconds]);

  const startTimer = () => {
    let intervalId = setInterval(() => {
      if (remainingSeconds > 0) {
        setRemainingSeconds(remainingSeconds - 1);
      } else {
        clearInterval(intervalId); // Stop timer when it reaches 0
      }
    }, 1000);

    return intervalId;
  };

  const onSubmit = async (data) => {
    try {
      if (!terms) {
        toast.error("Please accept T&C before proceeding", {
          position: "bottom-center",
        });
        return;
      }
      const res = await axios.post(`${secondUrl}v1/verifyotp`, {
        name: data.name,
        email: data.email,
        otp: data.otp,
      });
      if (res.data.status === "success") {
        localStorage.setItem("tokenk", res.data.token);
        navigate("/");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong", {
        position: "bottom-center",
      });
    }
  };

  const otpHandler = async () => {
    try {
      const name = getValues("name");
      const email = getValues("email");
      if (!name || !email) {
        toast.error("Name and Email is required", {
          position: "bottom-center",
        });
        return;
      }
      const res = await axios.post(`${secondUrl}v1/register`, {
        name,
        email,
      });
      if (res.data && res.data.status === "success") {
        toast.success("Otp Sent", {
          position: "bottom-center",
        });
        setOtpStatus(true);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong", {
        position: "bottom-center",
      });
    }
  };

  const resendOtpHandler = async () => {
    try {
      const email = getValues("email");
      const data = { email: email };
      const res = await resendOtp(data);
      if (res.data && res.data.status === "success") {
        toast.success("Otp Sent");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong", {
        position: "bottom-center",
      });
    }
  };

  return (
    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
      <div className="form-section al-form mt-4">
        <h3>Create account</h3>
        <p className="signup-top">For business, band or celebrity. </p>
        <form className="form-ui" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex-column-al">
            <label>Name</label>
          </div>
          <Input
            parentClass={"inputForm-al"}
            type={"text"}
            inputClass={"input-al"}
            placeholder={"Bill Sanders"}
            name={"name"}
            event={register}
            error={errors}
          />
          <div className="flex-column-al">
            <label>Email </label>
          </div>
          <Input
            parentClass={"inputForm-al"}
            type={"text"}
            inputClass={"input-al"}
            placeholder={"bill.sanders@example.com"}
            name={"email"}
            event={register}
            error={errors}
          >
            <div
              className="send-otp"
              onClick={() => {
                if (!otpStatus) {
                  otpHandler();
                }
              }}
            >
              <span className="otp-ui">
                {otpStatus ? "OTP SENT" : "SEND OTP"}
              </span>
            </div>
          </Input>

          <div className="flex-column-al">
            <label> Enter OTP </label>
          </div>
          <Input
            parentClass={"inputForm-al"}
            type={"text"}
            inputClass={"input-al"}
            placeholder={""}
            name={"otp"}
            event={register}
            disabled={!otpStatus}
            error={errors}
          >
            <div className="send-otp">
              {remainingSeconds > 0 ? (
                <span className="otp-ui ms-2 otp-left">
                  ({remainingSeconds}s)
                </span>
              ) : (
                <span
                  className="otp-ui ms-2 otp-left"
                  onClick={resendOtpHandler}
                >
                  RESEND
                </span>
              )}
            </div>
          </Input>

          <div className="form-check form-check-bottom d-flex gap-2 al-align">
            <input
              className="form-check-input mt-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              checked={terms}
              onClick={() => setTerms(!terms)}
            />
            <label className="form-check-label" for="flexCheckDefault">
              <div className="text-p">
                <p>
                  I agree to all the <span> Terms </span> and{" "}
                  <span> Privacy policy </span>
                </p>
              </div>
            </label>
          </div>
          <button className="button-submit" type="submit">
            Create account
          </button>

          <div className="separator">
            <hr className="line" />
            <span>Or</span>
            <hr className="line" />
          </div>
          <SocialRegister />
        </form>
      </div>
    </div>
  );
};

export default RegisterForm;
