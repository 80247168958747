import { backEndUrl } from "../../../Network/path";

const DaillingInterface = ({ style }) => {
  return (
    <div className={`spinner text-center ${style}`}>
      <img src={`${backEndUrl}spinner.gif`} />
    </div>
  );
};
export default DaillingInterface;
