import React from "react";

const Input = ({
  parentClass,
  inputClass,
  type,
  placeholder,
  children,
  onChange,
  name,
  value,
  event,
  error,
  disabled,
}) => {
  return (
    <>
      <div className={parentClass}>
        <input
          type={type}
          className={inputClass}
          placeholder={placeholder}
          onChange={onChange}
          name={name}
          value={value}
          {...(event && event(name))}
          disabled={disabled}
        />
        {children}
      </div>
      {error && error[name] && (
        <p style={{ color: "red" }}>{error[name].message}</p>
      )}
    </>
  );
};

export default Input;
