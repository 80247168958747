import React from "react";

import { Navigate } from "react-router-dom";

const withAuthorization = (Component, requiredRole) => {
  return (props) => {
    const { isAuthenticated, role } = checkAuth();

    if (!isAuthenticated) {
      return <Navigate to="/login" />;
    }

    if (requiredRole && role !== requiredRole) {
      return <Navigate to="/not-authorized" />;
    }

    return <Component {...props} />;
  };
};

const checkAuth = (selector) => {
  const lt = localStorage.getItem("tokenk");
  if (lt && lt !== "") {
    return { isAuthenticated: true, role: "admin" };
  } else {
    return { isAuthenticated: false, role: "user" };
  }
};

export default withAuthorization;
