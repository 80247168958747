import axios from "axios";
import { secondUrl } from "../Network/path.js";

export const fetchChat = async (body) => {
    try {
      const token = localStorage.getItem("tokenk");
      const response = await axios.post(`${secondUrl}v1/chat_widget_members`, body, {
        headers: {
          "x-access-token": token,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
};

export const chatSearchApi = async (body) => {
  try {
    const token = localStorage.getItem("tokenk");
    return await axios.post(`${secondUrl}v3/ChatSearch`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const searchChatUsers = async (body) => {
  try {
    const token = localStorage.getItem("tokenk");
    return await axios.post(`${secondUrl}v3/searchingChatUser`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const AddNewLogin = async (body) => {
  try {
    const response = await axios.post(`${secondUrl}v3/Login`, body);
    return response;
  } catch (error) {
    alert(error.message);
    throw error;
  }
}

export const updateEngage = async (data) => {
  try {
    const token = localStorage.getItem("tokenk");
    return await axios.post(`${secondUrl}v3/updateEngage`, data, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const resendOtp = async (data) => {
  try {
    return await axios.post(`${secondUrl}v1/resendOtp`, data)
  } catch (error) {
    throw error
  }
}