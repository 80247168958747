import React from 'react'

const Header = () => {
    return (
        <div class="container">
            <div class="row d-flex-al">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <div>
                        <h2 class="logo-text">LOGO </h2>
                    </div>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <div class="text-end text-end-mobile">
                        <i class="bi bi-x-square bi-x-square-icon"></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header