import { useContext, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import $ from "jquery";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { backEndUrl } from "../../../Network/path";
import ImagePreview from "../../ImagePreview";
import MessageBox from "../../MessageBox ";
import { fetchChat } from "../../../Services/api";
import { SocketContext } from "../../../Context";
const RightBar = () => {
  const {
    currentUser,
    offer,
    call,
    show,
    setShow,
    userMute,
    setOffer,
    socket,
    stream,
    setCurrentChat,
    expendMode,
  } = useContext(SocketContext);
  const location = useLocation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [files, setFile] = useState("");
  const [messages, setMessages] = useState([]);
  const [prev, setPrev] = useState();
  const [page, setPage] = useState(1);
  const [sms, setSms] = useState("");
  const messageEl = useRef(null);
  const selector = useSelector((state) => state?.auth);
  const [me, setme] = useState(0);
  const [usr, setu] = useState(0);
  const myref = useRef(null);

  useEffect(() => {
    if (myref.current) {
      myref.current.srcObject = stream;
    }
  }, []);

  useEffect(() => {
    let id = selector.auth.uid ? selector.auth.uid : selector.auth.id;
    let connect = call && call.from ? call.from : call.callUserId;
    setme(id);
    setu(connect);
    getChatFun(page);
    socket.on(`send_sms_${selector.auth?.key}_${id}_${connect}`, (data) => {
      setMessages((prevData) => [...prevData, data]);
    });
    return () => {
      socket.off(`send_sms_${selector.auth?.key}_${id}_${connect}`);
    };
  }, [page]);

  useEffect(() => {
    const scrollToBottom = () => {
      if (messageEl.current) {
        messageEl.current.scrollTop = messageEl.current.scrollHeight;
      }
    };

    if (messageEl.current) {
      const handleDOMNodeInserted = () => {
        scrollToBottom();
      };

      messageEl.current.addEventListener(
        "DOMNodeInserted",
        handleDOMNodeInserted
      );
    }

    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (messageEl.current) {
      messageEl.current.scrollTop = messageEl.current.scrollHeight;
    }
  }, [expendMode]);

  function getChatFun(page) {
    const body = {
      page: page,
      selectionId: call && call.from ? call.from : call.callUserId,
      id: selector.auth && selector.auth.uid,
    };

    fetchChat(body)
      .then((response) => {
        if (response.data.status) {
          if (response.data.data.length > 0) {
            if (page == 1) {
              setMessages(response.data.data);
            } else {
              setMessages([...response.data.data, ...messages]);
              messageEl.current.scrollTop =
                messageEl.current.scrollHeight - prev;
            }
          }
        }
      })
      .catch((e) => console.log("error", e));
  }

  const sendMessage = (data) => {
    if (data.socket) {
      socket.emit("stop_typing", { user: call?.callUserId });
      socket.emit("send_sms", data);
    }
    if (data.file_type === "application") {
      setFile((prev) => [...prev, data]);
    }
    setCurrentChat((prevData) => [...prevData, data]);
    setMessages((prevData) => [...prevData, data]);
  };

  useEffect(() => {
    if (offer) {
      var date = Math.round(new Date().getTime() / 1000);
      let dts = {
        first: messages.length > 0 ? false : true,
        sender_id: me,
        connection_id: call.connectionId,
        receiver_id: usr,
        type:
          sms !== "" && files !== ""
            ? "text_with_file"
            : files !== ""
            ? "file"
            : offer !== ""
            ? "application"
            : "text",
        file_type:
          selectedFile && selectedFile !== null
            ? selectedFile.type.split("/")[0]
            : "",
        file: files,
        offer: offer,
        sms_status: "sent",
        message: sms,
        unix_time: date,
        socket: location.pathname !== "/messages" ? true : false,
      };
      sendMessage(dts);
      setFile("");
      setSelectedFile(null);
      setOffer("");
      setSms("");
    }
  }, [offer]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (sms.trim() !== "" || files) {
      var date = Math.round(new Date().getTime() / 1000);
      let dts = {
        first: messages.length > 0 ? false : true,
        sender_id: me,
        connection_id: call.connectionId,
        receiver_id: usr,
        type:
          sms !== "" && files !== ""
            ? "text_with_file"
            : files !== ""
            ? "file"
            : offer !== ""
            ? "application"
            : "text",
        file_type:
          selectedFile && selectedFile !== null
            ? selectedFile.type.split("/")[0]
            : "",
        file: files,
        offer: offer,
        sms_status: "sent",
        message: sms,
        unix_time: date,
        socket: true,
      };
      sendMessage(dts);
      setSms("");
      setFile("");
      setSelectedFile(null);
      setOffer("");
    }
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    const token = localStorage.getItem("token");
    const headers = {
      "x-access-token": token,
    };
    let formData = new FormData();
    formData.append("file", file);
    axios
      .post(backEndUrl + "/apis/v3/uploadChat", formData, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data && response.data.file) {
          setFile(response.data.file);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const onScroll = () => {
    if (messageEl.current) {
      let { scrollTop, scrollHeight, clientHeight, offsetTop } =
        messageEl.current;
      if (prev !== scrollHeight) {
        setPrev(scrollHeight);
      }
      if (scrollTop == 0) {
        setTimeout(() => {
          setPage(page + 1);
        }, 300); // Adjust the debounce delay as needed
      }
    }
  };
  const textStyle = {
    fontSize: "18px",
  };

  return (
    <div className="chats">
      <div className="hdd">
        <h1>
          <video autoPlay muted playsInline ref={myref} />
        </h1>
        <h6 className="text-start">
          You{" "}
          <i
            className={
              userMute == false
                ? "bi bi-mic-mute float-end"
                : "bi bi-mic float-end"
            }
          ></i>
        </h6>
      </div>
      <div className="chatsall">
        {Object.keys(currentUser).length > 0
          ? selector.auth.uid !== currentUser.started_by && (
              <h4
                onClick={() => {
                  setShow(!show);
                }}
              >
                <i className="bi bi-plus"></i>
                Create Offer
              </h4>
            )
          : call &&
            call.started_by !== selector.auth.uid && (
              <h4
                onClick={() => {
                  if (
                    currentUser.started_by !== selector.auth.uid &&
                    messages.length === 0
                  ) {
                  } else {
                    setShow(!show);
                  }
                }}
              >
                <i className="bi bi-plus"></i>
                Create Offer
              </h4>
            )}

        <span
          className="close"
          onClick={() => {
            $(".chatsall").removeClass("main");
          }}
        >
          Back to call
          <br /> <i className="bi bi-arrow-down-short" style={textStyle}></i>
        </span>
        <div
          className={`msg1 ${
            currentUser && selector.auth.uid == currentUser.started_by
              ? "nocreateoffer"
              : ""
          }`}
        >
          <div className="msgin" ref={messageEl} onScroll={onScroll}>
            <div className="row">
              {messages &&
                messages.length > 0 &&
                messages.map((x, i) => {
                  return (
                    <MessageBox
                      data={x}
                      right={me == x.sender_id ? true : false}
                      style={
                        me == x.sender_id
                          ? "col-sm-12 text-end text-end"
                          : "col-sm-12 text-start"
                      }
                    />
                  );
                })}
            </div>
          </div>
        </div>
        <ImagePreview
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
        {offer !== "" && <p>New Offer Added</p>}
        <form onSubmit={handleSubmit}>
          {messages.length === 0 &&
          currentUser.started_by !== selector.auth.uid ? (
            <p className="text-center">Customer must message first</p>
          ) : (
            <>
              <input
                type="text"
                id="messageText"
                placeholder="Type a message...."
                value={sms}
                onChange={(e) => setSms(e.target.value)}
                disabled={
                  currentUser.started_by !== selector.auth.uid &&
                  messages.length === 0
                }
              />
              <button type="submit">
                <i className="bi bi-send-fill"></i>
              </button>
              <span>
                <i className="fa-solid fa-paperclip"></i>
                <input
                  type="file"
                  accept="image/* , application/doc , application/docx , application/pdf , application/zip , application/rar"
                  onChange={handleChange}
                  disabled={
                    currentUser.started_by !== selector.auth.uid &&
                    messages.length === 0
                  }
                />
              </span>
            </>
          )}
        </form>
      </div>
    </div>
  );
};
export default RightBar;
