import React, { useContext, useEffect, useRef, useState } from "react";
import { ImCross } from "react-icons/im";
import { useSelector } from "react-redux";
import $ from "jquery";
import { useLocation, useNavigate } from "react-router-dom";
import ChatUser from "../../../Components/ChatUser";
import { searchChatUsers } from "../../../Services/api";
import { SocketContext } from "../../../Context";

function LeftSidebar({ page, setPage }) {
  const {
    chatUser,
    setCurrent,
    socket,
    setChatUser,
    screenWidth,
  } = useContext(SocketContext);
  const selector = useSelector((state) => state.auth);
  const [searchData, setSearchData] = React.useState("");
  const [userPage, setUserPage] = useState(1);
  const dt = localStorage.getItem("dt");
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const usersRef = useRef();
  useEffect(() => {
    searchHandle(searchData);
  }, [userPage]);

  const searchHandle = (value) => {
    const body = {
      search: value,
      id: selector.auth.uid,
      page: userPage,
      pageSize: 15,
    };
    searchUserFun(body);
  };

  const searchUserFun = async (body) => {
    try {
      const { data } = await searchChatUsers(body);
      if (userPage > 1) {
        setChatUser([...chatUser, ...data.data]);
      } else {
        setChatUser(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchUser = async (value) => {
    const body = {
      search: value,
      id: selector.auth.uid,
      page: 1,
      pageSize: 15,
    };
    try {
      const { data } = await searchChatUsers(body);
      setChatUser(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const setChatModal = (user) => {
    const msg = queryParams.get("msg");
    if (msg && msg != "") {
      queryParams.delete("msg");
      const newSearchString = queryParams.toString();
      navigate({ pathname: "/messages", search: newSearchString });
    }
    $(".test").addClass("main");
    user.permission = user.application_process;
    setCurrent(user);
    setPage(1);
    const data = { selectId: user.connection_user };
    socket.emit("fetch_chat_by_users", data);
  };

  const handleScroll = () => {
    if (usersRef.current) {
      let { scrollTop, scrollHeight, clientHeight, offsetTop } =
        usersRef.current;
      if (clientHeight + scrollTop === scrollHeight) {
        setUserPage(userPage + 1);
      }
    }
  };

  return (
    <>
      <div className="chat_user main" onScroll={handleScroll} ref={usersRef}>
        <div className="chatSearch">
          <input
            type="text"
            placeholder="Search users"
            value={searchData}
            onChange={(e) => {
              setUserPage(1);
              setSearchData(e.target.value);
              searchUser(e.target.value);
            }}
          />
          {searchData && (
            <ImCross
              onClick={() => {
                setChatUser([]);
                setUserPage(1);
                setSearchData("");
                searchHandle("");
              }}
            />
          )}
          <i className="bi bi-search"></i>
        </div>

        <ul className="alluser">
          {chatUser.length > 0 ? (
            chatUser.map((x, i) => {
              return (
                <ChatUser
                  click={() => {
                    setChatModal(x);
                  }}
                  data={x}
                  id={x.id}
                  index={i}
                />
              );
            })
          ) : (
            <div class="search_image text-center mt-4">
              <img class="search_image" src="/assets/images/image_search.png" />
              <h3>No Data Found</h3>
            </div>
          )}
        </ul>
      </div>
    </>
  );
}
export default LeftSidebar;
