import React from "react";

const LeftSide = () => {
  return (
    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
      <div class="text-center">
        <h2>"Join the conversation and connect with your friends." </h2>
      </div>
      <div class="play-video py-150">
        <svg viewBox="0 0 60 60" width="50px" height="50px">
          <title>play icon</title>
          <g>
            <path
              class="play"
              fill="#f1f1f1"
              d="M24.89,40.84c-0.37,0.22-0.83,0.23-1.2,0.02s-0.6-0.61-0.6-1.04V20.2c0-0.43,0.23-0.83,0.6-1.04
                c0.37-0.21,0.83-0.21,1.2,0.02l16.35,9.81c0.36,0.21,0.58,0.6,0.58,1.02s-0.22,0.81-0.58,1.02L24.89,40.84z"
            ></path>
            <path
              class="pause hidden"
              fill="#f1f1f1"
              d="M28.03,19.06v21.88c0,0.86-0.7,1.56-1.56,1.56h-3.12c-0.86,0-1.56-0.7-1.56-1.56V19.06
                c0-0.86,0.7-1.56,1.56-1.56h3.12C27.33,17.5,28.03,18.2,28.03,19.06z M38.46,19.06v21.88c0,0.86-0.7,1.56-1.56,1.56h-3.12
                c-0.86,0-1.56-0.7-1.56-1.56V19.06c0-0.86,0.7-1.56,1.56-1.56h3.12C37.76,17.5,38.46,18.2,38.46,19.06z"
            ></path>
            <path
              class="stroke-bg"
              fill="none"
              stroke="#999"
              stroke-width="4"
              d="M30,7C17.32,7,7,17.32,7,30
            c0,12.68,10.32,23,23,23c12.68,0,23-10.32,23-23C53,17.32,42.68,7,30,7z"
            ></path>
            <path
              class="stroke"
              fill="none"
              stroke="#f1f1f1"
              stroke-width="4"
              d="M30,7C17.32,7,7,17.32,7,30
            c0,12.68,10.32,23,23,23c12.68,0,23-10.32,23-23C53,17.32,42.68,7,30,7z"
            ></path>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default LeftSide;
