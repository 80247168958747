import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AddNewLogin } from "../Services/api";


export const loginUser = createAsyncThunk(
  "otpvrification",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await AddNewLogin(formData);
      localStorage.setItem("userk", JSON.stringify(response.data.data));
      localStorage.setItem("tokenk", response.data.token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);


const authSlice = createSlice({
  name: "auth",
  initialState: {
    submitting: false,
    error: null,
    success: false,
    auth: {},
    userDetails: {},
    token: "",
    message: "",
    chats: [],
    currentUser: {}
  },
  reducers: {
    setUser: (state, action) => {
      state.auth = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
      localStorage.setItem("userDetails", JSON.stringify(action.payload));
    },
    setChats: (state, action) => {
      state.chats = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.submitting = false;
        state.auth = action.payload.data;
        state.token = action.payload.token;
        state.loading = false;
        state.success = true;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.data.message;
        state.success = false;
        state.loading = false;
      })
  },
});
export const {
  setUser,
  setToken,
  setUserDetails,
  setChats,
} = authSlice.actions;
export default authSlice.reducer;
