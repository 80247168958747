import React from "react";
import LeftSide from "./leftSide";
import LoginForm from "./loginForm";

function Login() {
  return (
    <div class="container py-100">
      <div class="row">
        <LeftSide />
        <LoginForm />
      </div>
    </div>
  );
}

export default Login;
