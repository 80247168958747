import React from 'react';
import './NotAuthorized.css'; // Import the CSS file

function NotAuthorized() {
  return (
    <div className="not-authorized">
      <h1>Access Denied</h1>
      <p>You do not have permission to access this page.</p>
      <a href="/">Go to Home</a>
    </div>
  );
}

export default NotAuthorized;
