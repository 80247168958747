import UserInterface from "./UserInterface";
import { useContext } from "react";
import DaillingInterface from "./DaillingInterface";
import { SocketContext } from "../../../Context";
const Calling = () => {
  const { callAccepted } = useContext(SocketContext);
  return (
    <>
      <UserInterface style={!callAccepted ? "d-none" : ""} />
      <DaillingInterface style={callAccepted ? "d-none" : ""} />
    </>
  );
};

export default Calling;
  