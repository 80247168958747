import { useEffect, useState } from "react";
import { ReactInternetSpeedMeter } from "react-internet-meter";
import Alert from 'react-bootstrap/Alert';
import { Tooltip } from "react-tooltip";
import $ from 'jquery';
import { mediaUrl } from "../../../Network/path";

const PlayerHeader = ({ currentUser, direactCut, callCut, call, time, muteFun, mute,expendMode, setExpendMode }) => {
  const [userDetail, setUserDetail] = useState(null);
  const [checkSpeed, SetCheckSpeed] = useState("Finding...");
  const [checkSpeedClass, SetCheckSpeedClass] = useState("bi bi-wifi");
  useEffect(() => {
    if (call && Object.keys(call).length > 0) {
      setUserDetail(call);
    } 
    else if (currentUser && Object.keys(currentUser).length !== 0 && Object.keys(call).length === 0) {
      setUserDetail(currentUser);
    }
  }, [checkSpeedClass]);

  const fetchNetwork = (data) => {
    let speed = Number(data);
  
    switch (true) {
      case speed <= 60 && speed >= 10:
        SetCheckSpeedClass("bi bi-wifi-2");
        break;
      case speed < 10 && speed >= 0.1:
        SetCheckSpeedClass("bi bi-wifi-1");
        break;
      case speed < 0.1:
        setTimeout(() => {
          if (speed < 0.05) {
            direactCut("1");
          }
        }, 3000);
        SetCheckSpeedClass("bi bi-wifi-off");
        break;
      default:
        SetCheckSpeedClass("bi bi-wifi");
        break;
    }
  };

  const expand =()=>{
     setExpendMode(!expendMode);
    $('.call_maini').addClass('call_fin');
    $('.call_maini').removeClass('call_2');
    $('.exx').addClass('d-none');
    $('.crr').removeClass('d-none');
  }

  const contract = () =>{
     setExpendMode(!expendMode);
    $('.call_maini').addClass('call_2');
    $('.call_maini').removeClass('call_fin');
    $('.crr').addClass('d-none');
    $('.exx').removeClass('d-none');
  }

  const resize = () =>{
    if(window.innerWidth<=767 && !expendMode){
      expand();
    }
  }


  return (
    <div className="hdr">
      <ul className="call_minimum">
        {window.innerWidth>767 || expendMode ? <li>
        <>
        {
          expendMode ? <span onClick={()=>contract()} data-tooltip-id={"contract"} ><i class="bi bi-arrows-angle-contract"></i></span> : <span onClick={()=>expand()} data-tooltip-id={"expand"}><i class="bi bi-arrows-angle-expand"></i></span>
        }</>
        </li> :<>
        <li><span onClick={()=>expand()} data-tooltip-id={"expand"}><i class="bi bi-arrows-angle-expand"></i></span></li>
        <li onClick={()=>muteFun()} data-tooltip-id={"mice"}>
        <a>
          <i
            className={
              mute == true ? "bi bi-mic" : "bi bi-mic-mute-fill"
            }
          ></i>
        </a>
      </li></>}
      {expendMode && window.innerWidth<=767  ? <></> : <li data-tooltip-id="end" onClick={() => callCut()} ><a class="telephone"><i class={expendMode || window.innerWidth>767 ? "bi bi-x" : "bi bi-telephone-fill"} ></i></a></li>}
      </ul>
      <div className="container-fluid" onClick={()=>resize()}>
        <div className="row">
          <div className="col-6">
            {checkSpeedClass && checkSpeedClass==="bi bi-wifi-1" && <Alert variant="warning" severity="warning">Internet connection is slow
            </Alert>}
            {checkSpeedClass && checkSpeedClass==="bi bi-wifi-off" && <Alert variant="danger" severity="danger">Internet connection is slow call automatically disconnect in few seconds
              </Alert>}
            <ReactInternetSpeedMeter
              txtSubHeading="Internet connection is slow"
              outputType="" // "alert"/"modal"/"empty"
              customClassName={null}
              pingInterval={2000} // milliseconds
              txtMainHeading="Opps..."
              thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
              threshold={50}
              imageUrl="https://apiofficefront.chownk.in/cropped-9675.png"
              downloadSize="1561257" //bytes
              callbackFunctionOnNetworkDown={(data) =>{
                SetCheckSpeed(data);
                fetchNetwork(data);
              }}
              callbackFunctionOnNetworkTest={(data) => {
                SetCheckSpeed(data);
                fetchNetwork(data);
              }}
            />
            {userDetail !== null && (
              <h6>
                <b>
                {userDetail.dynamic_image === 0 ? (
                  <span
                    style={{
                      backgroundImage: `url(${mediaUrl + userDetail.image})`,
                    }}
                  ></span>
                ) : (
                  <span>{userDetail.name && userDetail.name.charAt(0)}</span>
                )}
                {(userDetail.userRole === "branch" ||
                  userDetail.userRole === "staff")
                  ? userDetail.company_name
                  : userDetail.name }
                  </b>
                <br />
                {(userDetail.userRole === "branch" ||
                  userDetail.userRole === "staff") && <label>{userDetail.name}</label>}
              </h6>
            )}
          </div>
          <div className="col-6">
            <ul className="rightsbr">
              <li>
                <i className={checkSpeedClass}></i>
                <span className="display-1">{checkSpeed} MB/s</span>
              </li>
              <li>{time}</li>
            </ul>
          </div>
        </div>
      </div>
      <Tooltip id="end" content="End call" style={{zIndex:999}}/>
      <Tooltip id="contract" content="Contract" style={{zIndex:999}}/>
      <Tooltip id="expend" content="Expend" style={{zIndex:999}}/>
    </div>
  );
};
export default PlayerHeader;
