import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { updateEngage } from "../../../Services/api";
import { SocketContext } from "../../../Context";
const audio = new Audio("/assets/audio/iphone.mp3");
const View = ({ onHide }) => {
  let aud = false;
  const {
    call,
    ans,
    setAnsFun,
    setAns,
    mute,
    video,
    callTrue,
    declineOwnClick,
    setCallTrue,
    cut,
    access,
    acceptClick,
    setCall,
    declineClick,
    setDeclineCall,
    CheckAudioAccess,
    callAccepted,
    startRecording,
    setHasCamera,
    setMute,
  } = useContext(SocketContext);

  const [counter, setCounter] = useState(60);
  const [sms, setSms] = useState("");
  let m = false;
  useEffect(() => {
    if (call.isReceivingCall && !access) {
      aud = true;
      audio.load();
      audio.play();
      audio.loop = true;
    } else {
      audio.pause();
    }

    if (call.isReceivingCall && call.types == "decline") {
      decline("decline");
    }
    if (callTrue) {
      checkCamMic();
    }
  }, [callTrue, call.isReceivingCall, access]);

  const checkCamMic = async () => {
    try {
      const ad = await CheckAudioAccess();
      if (ad) {
        ansthecall();
        setHasCamera(null);
        setMute(true);
        onHide();
      } else {
        setHasCamera("Webcam or microphone not accessible");
      }
    } catch (e) {
      setHasCamera(e.message);
    }
  };

  const ansthecall = async () => {
    try {

        if (mute == true || video == true) {
          audio.pause();
          localStorage.setItem("connV", call.connectionId);
          audio.pause();
          aud = false;
          setAnsFun();
          setAns(false);
          setCounter(60);
          setCallTrue(false);
        } else {
          toast.error("Webcam and microphone not accessible");
        }
    } catch (e) {
      setSms(e.message);
    }
  };

  const decline = async (status) => {
    if (!m) {
      setDeclineCall(false);
      setCounter(60);
      try {
        aud = false;
        audio.pause();
        setCall({ ...call, isReceivingCall: false });
        onHide();
        setAns(false);
        const up = await updateEngage({
          status: status,
          qid: call.connectionId,
        });
        if (status != "dialer cut") {
          cut(call.single, call.connectionId, call.from);
        }
        setTimeout(() => {
          m = true;
        }, 600);
      } catch (e) {
        audio.pause();
      }
    }
    setSms("");
  };

  const concelCallDailer = async () => {
    decline("dialer cut");
  };

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      decline("not answer");
    }
  }, [counter]);

  return (
    <>
      {call.isReceivingCall && !callAccepted && (
        <div className="modal-content">
          <h6 className="text-center">
            This call automatically disconnected in{" "}
            <span>{"00:" + counter}</span>
          </h6>
          <div className="modal-body text-center">
            <h3>
              <img src="../../assets/images/calling_on_chownk.gif" />
              Incoming Call...
            </h3>
            <img
              src="../../assets/images/modelLogo.png"
              className="modelLogo"
            />
            <p>
              {(call.userRole === "branch" || call.userRole === "staff") &&
              call.dynamic_image === 0
                ? `${call.name}-${call.company_name}`
                : call.name}
            </p>
          </div>

          <div className="modal-footer d-block ">
            <button
              type="button"
              ref={acceptClick}
              className={`btn btn-primary ${!mute && !video ? "disable" : ""}`}
              onClick={() => checkCamMic()}
            >
              <i className="bi bi-telephone-fill"></i> Accept
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              ref={declineClick}
              onClick={() => {
                decline("decline");
              }}
            >
              <i className="bi bi-telephone-inbound-fill"></i> Decline
            </button>
            <button
              onClick={() => {
                concelCallDailer();
              }}
              ref={declineOwnClick}
              className="d-none"
            ></button>
          </div>
          {!mute && !video && (
            <p className="text-center px-3 notsup">
              {"Your system not supported webcem access"}
            </p>
          )}
          {sms && sms != "" && <p className="text-center px-3 notsup">{sms}</p>}
        </div>
      )}
    </>
  );
};
export default View;
