const ImagePreview = ({ selectedFile, setSelectedFile }) => {
  const renderFilePreview = () => {
    if (!selectedFile) return null;

    const { type } = selectedFile;

    if (type.startsWith("image/")) {
      return (
        <img
          src={URL.createObjectURL(selectedFile)}
          className="view"
          loading="lazy" alt="Preview"
          style={{ maxWidth: "100%" }}
        />
      );
    } else if (type.startsWith("audio/")) {
      return (
        <audio controls className="w-100">
          <source src={URL.createObjectURL(selectedFile)} type={type} />
          Your browser does not support the audio tag.
        </audio>
      );
    } else if (type.startsWith("video/")) {
      return (
        <video controls className="w-100" height={200}>
          <source src={URL.createObjectURL(selectedFile)} type={type} />
          Your browser does not support the video tag.
        </video>
      );
    } else if (type.startsWith("application/")) {
      return (
        <a className="pdf" href={URL.createObjectURL(selectedFile)} download>
          <i className="bi bi-file-earmark-pdf-fill"></i>
        </a>
      );
    } else {
      return <p>Unsupported file type</p>;
    }
  };
  return (
    <>
      {selectedFile ? (
        <div className="mediaSelection">
          <i className="bi bi-x-lg" onClick={() => setSelectedFile(null)}></i>
          {renderFilePreview()}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ImagePreview;
