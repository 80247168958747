import axios from "axios";
import React, { useEffect, useState } from "react";
import GoogleLogin from "./googleLogin";

const Connection = () => {
    const [pages, setPages] = useState({});
    const fbAuth = JSON.parse(localStorage.getItem("fbAuth"));

    useEffect(() => {
        if (fbAuth) {
            checkToken(fbAuth.accessToken);
        }
    }, []);

    const fetchPages = (access_token) => {
        window.FB.api(
            "me/accounts",
            { access_token: access_token },
            function (response) {
                if (response && !response.error) {
                    console.log(response, "pages")
                    setPages(response);
                }
            }
        );
    };
    const handleLogin = () => {
        window.FB.login(
            function (response) {
                if (response.authResponse) {
                    localStorage.setItem("fbAuth", JSON.stringify(response.authResponse));
                    getLongLivedToken(response.authResponse.accessToken);
                    window.FB.api(
                        "/me",
                        { fields: "name, email , picture" },
                        function (response) {
                            if (response && !response.error) {
                                fetchPages();
                            }
                        }
                    );
                } else {
                    console.log("User cancelled login or did not fully authorize.");
                }
            },
            { scope: "email, pages_show_list,public_profile" }
        );
    };

    const getLongLivedToken = async (short_token) => {
        try {
            const res =
                await axios.get(`https://graph.facebook.com/v20.0/oauth/access_token?  
            grant_type=fb_exchange_token&          
            client_id=${"931508892070052"}&
            client_secret=${"40424b6696f9a9bbf6e32e8145497163"}&
            fb_exchange_token=${short_token}`);

            if (res && res.data) {
                const auth = JSON.parse(localStorage.getItem("fbAuth"));
                localStorage.setItem(
                    "fbAuth",
                    JSON.stringify({ ...auth, accessToken: res.data.access_token })
                );
            }
        } catch (error) {
            console.log(error);
        }
    };

    const checkToken = async (token) => {
        try {
            const res = await axios.get(
                `https://graph.facebook.com/debug_token?input_token=${token}&access_token=${token}`
            );
            if (res.data.data.is_valid) {
                fetchPages(token);
            } else {
                localStorage.removeItem("fbAuth");
            }
        } catch (error) {
            console.log(error);
            localStorage.removeItem("fbAuth");
        }
    };

    return (
        <div style={{ padding: "20px" }}>
            {!fbAuth && <button onClick={handleLogin}>Connect with FB</button>}

            {pages && Object.keys(pages).length > 0 && (
                <>
                    <h3>Pages</h3>
                    <div>
                        <ul>
                            {pages.data.map((el, i) => (
                                <>
                                    <li>
                                        <p>{el.name}</p>
                                        <button>Connect</button>
                                    </li>
                                </>
                            ))}
                        </ul>
                    </div>
                </>
            )}
            <GoogleLogin />
        </div>
    );
};

export default Connection;
